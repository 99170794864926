body {
  background-color: #f5f7fa !important;
  height: 100%;
  /*font-family: "Montserrat", sans-serif;*/
}

.btn_est {
  min-width: 100px;
}
.modal-xl {
  max-width: 90% !important;
}
.modal-xxl {
  max-width: 95% !important;
}
.modal-completo {
  max-width: 100% !important;
}

.bloquea-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
